<template>
  <div
      class="formGroup"
      :class="{'formGroup--withIcon' : $slots.icon}"
  >
    <BaseLabel
        :label="label"
        :uuid="uuid"
        :required="required"
    />
    <div class="formGroup__wrapper">
      <slot name="icon"></slot>
      <BaseInput
          v-if="!isTextarea"
          :type="type"
          :placeholder="placeholder"
          :uuid="uuid"
          :error="error"
          v-bind:value="modelValue"
          v-on:input="$emit('update:modelValue', $event.target.value)"
      />
      <BaseTextArea
          v-if="isTextarea"
          :placeholder="placeholder"
          :uuid="uuid"
          :error="error"
          v-bind:value="modelValue"
          v-on:input="$emit('update:modelValue', $event.target.value)"
      />
      <img
          src="../assets/images/icons/cross-circle.svg"
          v-if="error && showErrorIcon"
          alt=""
          class="formGroup__error"
          width="18"
          height="18"
          loading="lazy"
      >
      <slot name="button"></slot>
    </div>
    <div
        v-if="help && !error"
        :id="`${uuid}-helpBlock`"
        class="formText"
    >
      {{ help }}
    </div>
    <FormValidation
        v-if="error"
        :id="`${uuid}-error`"
    >
      {{ error }}
    </FormValidation>
  </div>
</template>

<script>
import BaseInput from "@/components/BaseInput";
import BaseLabel from "@/components/BaseLabel";
import BaseTextArea from "@/components/BaseTextArea";
import FormValidation from '@/components/FormValidation'

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    uuid: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    help: {
      type: String,
      default: ''
    },
    showErrorIcon: {
      type: Boolean,
      default: true
    },
    isTextarea: {
      type: Boolean,
      default: false
    },
  },
  components: {
    BaseTextArea,
    BaseLabel,
    BaseInput,
    FormValidation
  }
}
</script>