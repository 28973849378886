import {isApiResponseValid} from "@/helpers/helpers";
import {get, patch, post} from "@/services/api";
import {AppError} from "@/services/errors";
import store from "@/store";

/**
 * Get current user data from localStorage or from api
 * @param {boolean} getFromStorage - if can get data from local storage
 * @return {object} - user data
 */
async function getCurrentUser(getFromStorage = true) {
    try {
        const response = await get('users/me');

        // check response
        if (!isApiResponseValid(response)) {
            throw new AppError('Error loading data', {"message": 'Chyba při načítání uživatele, zkuste obnovit stránku.', 'type': 'warning'})
        }

        // update data
        return response.data;
    } catch (err) {
        if (err instanceof AppError && err.data) {
            store.dispatch('app/addFlashMessage', err.data)
        } else {
            throw err;
        }
    }
}

/**
 * Make api call to user login
 * @param {string} firstname - firstname
 * @param {string} lastname - lastname
 * @param {string} email - email
 * @param {string} password - password
 * @param {string} phone - phone
 * @param {boolean} newsletter_subscribed - newsletter
 * @return {json} - return response from api
 */
async function createUser(firstname, lastname, email, password, phone = '', newsletter_subscribed = false) {
    return post('users', {
        firstname,
        lastname,
        phone,
        email,
        password,
        newsletter_subscribed,
        language: 5
    }, false)
}

/**
 * Make api call to update user data
 * @param {number} userId - userId
 * @param {string} firstname - firstname
 * @param {string} lastname - lastname
 * @param {string} email - email
 * @param {string} phone - phone
 * @return {json} - return response from api
 */
async function updateUser(userId, firstname, lastname, email, phone = '') {
    return patch(`users/${userId}`, {
        firstname,
        lastname,
        phone,
        email,
        language: 5
    }, false)
}

export const userService = {
    getCurrentUser,
    createUser,
    updateUser,
};