<template>
  <TheHeader/>
  <main :class="className">
    <TheBreadcrumb
        :path="breadcrumb"
    />

    <slot/>

    <!--    <div style="height: 5000px"></div>-->
  </main>
  <TheFooter/>
  <FlashMessages/>
</template>

<script>
import FlashMessages from "@/components/FlashMessages";
import TheBreadcrumb from "@/components/TheBreadcrumb";
import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '@/components/TheHeader.vue'

export default {
  props: {
    breadcrumb: {
      type: Array,
      default: null
    },
    className: {
      type: String,
      default: null
    }
  },
  components: {
    TheBreadcrumb,
    FlashMessages,
    TheHeader,
    TheFooter,
  }
}
</script>
