import {dateFormat, durationFormat, machineDateFormat} from "@/helpers/helpers";

export default {
    methods: {
        durationFormat(duration) {
            return durationFormat(duration);
        },
        dateFormat(date) {
            return dateFormat(date);
        },
        machineDateFormat(date) {
            return machineDateFormat(date);
        }
    }
}