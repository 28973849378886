<template>
  <div
      class="courseCard"
      :class="{'courseCard--completed' : isCompleted}"
  >
    <a
        :href="`courses/${data.url}`"
        :title="data.name"
        @click.prevent="openDetail"
        class="courseCard__link"
    ></a>
    <div class="courseCard__image">
      <img
          :src="data.image"
          alt=""
          width="300"
          height="200"
          loading="lazy"
      >
      <BaseBadge v-if="isCompleted">
        {{ translation('video_completed') }} {{ dateFormat(data.date_finished) }}
      </BaseBadge>
      <BaseBadge v-if="isEnrolled" :color="'red'">
        {{ translation('video_enrolled') }}
      </BaseBadge>
    </div>
    <div class="courseCard__content">
      <div class="courseCard__content-info">
        <BaseBadge :color="difficultyBadgeColor">{{ getCodeBookByKeyAndValue('course_difficulty', data.difficulty) }}</BaseBadge>
        <BaseDuration :duration="data.duration"/>
      </div>
      <h2 class="courseCard__title">{{ data.name }}</h2>
      <div class="courseCard__description" v-html="data.description_short"/>
    </div>
  </div>
</template>

<script>
import BaseBadge from "@/components/BaseBadge";
import BaseDuration from "@/components/BaseDuration";
import codeBooksMixin from "@/mixins/codeBooksMixin";
import formatMixins from "@/mixins/formatMixins";
import {enrollmentsService} from "@/services/enrollments.service";
import store from "@/store";
import {mapState} from "vuex";

export default {
  components: {BaseDuration, BaseBadge},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  mixins: [codeBooksMixin, formatMixins],
  computed: {
    ...mapState("user", {
      userData: (state) => state.userData,
    }),
    isCompleted() {
      return this.data.date_finished;
    },
    isEnrolled() {
      return this.data.date_enrolled && !this.isCompleted;
    },
    difficultyBadgeColor() {
        if(this.data.difficulty === 1)
            return 'blue'
        else if(this.data.difficulty === 2)
            return 'yellow'
        else
            return 'red'
    }
  },
  methods: {
    async openDetail() {
      if (this.data.date_enrolled) {
        this.redirect()
      } else {
        try {
          await enrollmentsService.enrollCourse(this.data.id, this.userData.id)
          this.redirect()
        } catch (err) {
          store.dispatch('app/addFlashMessage', {message: this.translation('global_alert-general')})
        }
      }
    },
    redirect() {
      location.href = `/courses/${this.data.url}`;
    }
  }
}
</script>