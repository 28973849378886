<template>
  <template v-for="(item, key) in pages" :key="key">
    <TheHeaderNavigationListItem
        v-if="!item.requiresAuth || (item.requiresAuth && loggedIn)"
        :page="key"
        :url="item.url"
    />
  </template>
</template>

<script>
import TheHeaderNavigationListItem from "@/components/TheHeaderNavigationListItem";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      pages: {
        about: {
          url: '/about',
          requiresAuth: true,
        },
        courses: {
          url: '/courses',
          requiresAuth: true,
        },
        dashboard: {
          url: '/dashboard',
          requiresAuth: true,
        },
      }
    }
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
  components: {
    TheHeaderNavigationListItem
  },
}
</script>