import _ from "lodash";

/**
 * Check if response has data, data is object or array
 * @param {object} response - endPoint
 * @return {boolean} - axios data
 */
function isApiResponseValid(response) {
    return response && response.data && (_.isPlainObject(response.data) || _.isArray(response.data));
}

/**
 * Convert duration in seconds to string {x}h {x}m {x}s
 * @param {number} duration - duration in seconds
 * @return {string} - formatted string
 */
function durationFormat(duration) {
    if (!duration) {
        return ''
    }

    let timeInSeconds = duration;
    const hours = Math.floor(timeInSeconds / 3600);
    timeInSeconds = timeInSeconds - hours * 3600;

    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds - (minutes * 60);

    return hours > 0 ? `${hours}h ${minutes}m ${seconds}s` : minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`
}

/**
 * Convert UTC date string to
 * @param {Date} date - date string
 * @return {string} - formatted string
 */
function dateFormat(date) {
    let out;
    try {
        date = new Date(date);
        if (isNaN(date)) {
            throw new Error('invalid date');
        }
        out = date.toLocaleDateString("cz-CS")
        // todo change date string by locale
    } catch (err) {
        out = '';
    }

    return out;
}

/**
 * Convert UTC date string to YYYY-MM-DD foramt
 * @param {Date} date - date string
 * @return {string} - formatted string
 */
function machineDateFormat(date) {
    let out;
    try {
        date = new Date(date);
        if (isNaN(date)) {
            throw new Error('invalid date');
        }
        out = date.toISOString().split('T')[0]
        // todo change date string by locale
    } catch (err) {
        out = '';
    }

    return out;
}

/**
 * Create link to share url on social sites
 * @param {string} socialSite - date string
 * @param {string} url - url that you want share
 * @param {string} title - title (twitter wants title)
 * @return {string} - link
 */
function socialShare(socialSite, url, title) {
    let out;

    switch (socialSite) {
        case 'facebook':
            out = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
            break;
        case 'twitter':
            out = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title + ' ' + url)}`;
            break;
        case 'linkedin':
            out = `https://www.linkedin.com/cws/share?mini=true&url=${encodeURIComponent(url)}`;
            break;
    }

    return out;
}

export {
    isApiResponseValid,
    durationFormat,
    dateFormat,
    machineDateFormat,
    socialShare,
}