import {get} from "@/services/api";

/**
 * Get courses from api
 * @param {int|null} filter_difficulty - filter by difficulty [1-3]
 * @return Axios
 */
function getAll(filter_difficulty = 0) {
    let params;

    if (filter_difficulty > 0) {
        params = {
            params: {
                filter_difficulty
            }
        }
    }

    return get('courses/', {}, params);
}

/**
 * Get course from api
 * @param {int} id - course id
 * @return Axios
 */
function getCourse(id) {
    return get(`courses/${id}`, null);
}

export const coursesService = {
    getAll,
    getCourse
};