<template>
  <li>
    <a
        :href="url"
        :title="title"
        :class="{
          'is-active': currentPage === page
        }"
        :aria-current="currentPage === page ? 'page' : null"
        :target="openInNewWindow ? '_blank' : null"
    >
      <slot/>
    </a>
  </li>
</template>

<script>
import codeBooksMixin from "@/mixins/codeBooksMixin";
import {mapState} from 'vuex'

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    page: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    openInNewWindow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("page", {
      currentPage: (state) => state.currentPage,
    })
  },
  mixins: [codeBooksMixin],
}
</script>