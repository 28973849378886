export default {
    namespaced: true,
    state: {
        currentPage: null,
    },
    mutations: {
        SET_PAGE(state, data) {
            state.currentPage = data
        }
    },
    actions: {
        setPage({commit}, page) {
            commit('SET_PAGE', page);
        }
    }
}