<template>
  <NavigationListItem
      :page="page"
      :url="url"
  >
    <template v-if="page === 'blog'">Blog</template>
    <template v-else>{{ translation(`header_nav_link-${page}`) }}</template>
  </NavigationListItem>
</template>

<script>
import NavigationListItem from "@/components/NavigationListItem";
import codeBooksMixin from "@/mixins/codeBooksMixin";
import {mapState} from 'vuex'

export default {
  components: {NavigationListItem},
  props: {
    page: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState("page", {
      currentPage: (state) => state.currentPage,
    })
  },
  mixins: [codeBooksMixin],
}
</script>