import {get, patch, post, remove} from "@/services/api";

/**
 * Send analytical data about lecture by API
 * @param {object} data - analyticalData
 * @return Axios
 */
function sendAnalyticalData(data) {
    return patch(`enrollments/me/lecture`, data);
}

/**
 * Get courses enrolled by user
 * @return Axios
 */
function getUserEnrollments() {
    return get(`enrollments/me/courses`);
}

/**
 * Enroll user to course
 * @param {string} course_id - id of course
 * @param {string} user_id - id of user
 * @return Axios
 */
function enrollCourse(course_id, user_id) {
    return post(`enrollments/course`, {course_id, user_id});
}

/**
 * Get lecture data by ID from API
 * @param {int} id - lecture id
 * @return Axios
 */
function getLectureData(id) {
    return get(`enrollments/me/lectures/${id}`, null);
}

/**
 * Enroll user to lecture by API
 * @param {object} data - json data
 * @return Axios
 */
function lectureEnrollment(data) {
    return post(`enrollments/me/lecture/`, data);
}

/**
 * Complete lecture by API
 * @param {object} data - json data
 * @return Axios
 */
function completeLecture(data) {
    return post(`enrollments/me/lecture/complete`, data);
}

/**
 * Incomplete lecture by API
 * @param {int} id - lecture id
 * @return Axios
 */
function incompleteLecture(id) {
    return remove(`enrollments/me/lecture/${id}`);
}

/**
 * Delete course enrollment by API
 * @param {int} id - course id
 * @return Axios
 */
function deleteCourseEnrollment(id) {
    return remove(`enrollments/me/courses/${id}`);
}

export const enrollmentsService = {
    getUserEnrollments,
    enrollCourse,
    sendAnalyticalData,
    getLectureData,
    lectureEnrollment,
    completeLecture,
    incompleteLecture,
    deleteCourseEnrollment
};