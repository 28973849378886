import {enrollmentsService} from "@/services/enrollments.service";

export default {
    namespaced: true,
    state: {
        currentVideo: null,
        currentCourse: null,
        isPlaying: false
    },
    mutations: {
        SET_VIDEO(state, data) {
            state.currentVideo = data
            state.isPlaying = false
        },
        SET_COURSE(state, data) {
            state.currentCourse = data;
        },
        SET_PLAY(state) {
            state.isPlaying = true
        },
        SET_PAUSE(state) {
            state.isPlaying = false
        }
    },
    actions: {
        setVideo({commit}, data) {
            commit('SET_VIDEO', data);
        },
        setCourse({commit}, data) {
            commit('SET_COURSE', data);
        },
        async activateLecture({commit, state}, lectureId) {
            // Enroll user to lecture
            await enrollmentsService.lectureEnrollment({"lecture_id": lectureId});

            // Get current lecture data because of last video position
            const response = await enrollmentsService.getLectureData(lectureId);
            let lecture = response.data;

            // Init values for current video
            let currentVideo = state.currentVideo;
            currentVideo.lectureId = lectureId;
            currentVideo.sectionId = lecture.section_id;
            currentVideo.nextLectureId = lecture.id_next;
            currentVideo.lastVideoPosition = lecture.last_video_position;
            currentVideo.lectureDateFinished = lecture.date_finished;
            currentVideo.videoSourceUrl = lecture.video ? lecture.video.url : null;
            currentVideo.lectureDurationSeconds = lecture.video ? lecture.video.duration_seconds : null;
            currentVideo.userLectureId = lecture.user_lecture_id;
            currentVideo.lectureDescription = lecture.description;
            currentVideo.lectureDescriptionShort = lecture.description_short;
            currentVideo.lectureName = lecture.name;
            currentVideo.lectureFaq = lecture.faq;

            commit('SET_VIDEO', currentVideo);
        },
        play({commit}) {
            commit('SET_PLAY');
        },
        pause({commit}) {
            commit('SET_PAUSE');
        }
    }
}