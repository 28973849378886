<template>
  <footer class="theFooter">
    <div class="container container--sm">
      <div class="theFooter__wrapper">
        <div>
          <p class="h3">{{ translation('footer_nav_section-about') }}</p>
          <NavigationList :label="'Secondary'">
            <NavigationListItem
                :url="'https://drive.google.com/drive/folders/1-nkedDe5UIWZzKFV0JagLWTZ9Un_H146'"
                :openInNewWindow="true"
            >
              {{ translation('footer_nav_link-about') }}
            </NavigationListItem>
            <NavigationListItem
                :url="'https://drive.google.com/drive/folders/0B5BebB0iwq7YT2ZKanZQM3dhSXM?resourcekey=0-aALfD5VQpDiJyx8kYBGxmA'"
                :openInNewWindow="true"
            >
              {{ translation('footer_nav_link-articleList') }}
            </NavigationListItem>
            <NavigationListItem
                :url="'https://sites.google.com/goodcall.eu/ggmaps/homepage'"
                :openInNewWindow="true"
            >
              GoodGroup Maps
            </NavigationListItem>
          </NavigationList>
        </div>
        <div>
          <p class="h3">{{ translation('footer_nav_section-contact') }}</p>

          <NavigationList :label="'Contacts'">
            <NavigationListItem
                :url="'mailto:peoplecare@goodcall.eu'"
                :title="'E-mail'"
                :openInNewWindow="true"
            >
              peoplecare@goodcall.eu
            </NavigationListItem>
            <NavigationListItem
                :url="'mailto:development@goodcall.eu'"
                :title="'E-mail'"
                :openInNewWindow="true"
            >
              development@goodcall.eu
            </NavigationListItem>
          </NavigationList>
        </div>
      </div>

      <div class="theFooter__brands">
        <a href="/">
          <img
              src="../assets/images/goodtoknow_white.svg"
              alt="Goodcall"
              class="icon theFooter__brands-goodcall"
              width="97"
              height="30"
              loading="lazy"
          >
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import NavigationListItem from "@/components/NavigationListItem";
import NavigationList from "@/components/NavigationList";
import codeBooksMixin from "@/mixins/codeBooksMixin";

export default {
  components: {NavigationList, NavigationListItem},
  mixins: [codeBooksMixin]
}
</script>