<template>
  <header
      class="theHeader"
      ref="theHeader"
      :class="{
        'theHeader--scrolled' : isPageScrolled,
        'theHeader--hidden' : isHeaderVisible,
        'theHeader--logged' : loggedIn,
        'theHeader--openMobileNav' : isMobileNavigationActive,
      }"
  >
    <div class="container container--xLg">
      <div class="theHeader__inner">
        <div class="theHeader__brands">
          <a href="/">
            <img
                src="../assets/images/goodtoknow_black.svg"
                alt="Goodcall"
                class="icon theHeader__brands-goodcall"
                width="97"
                height="30"
            >
          </a>
        </div>
        <NavigationList
            class="theHeader__navigation theHeaderNavigation"
            :label="'Main'"
        >
          <TheHeaderNavigationList/>
        </NavigationList>
        <div class="theHeader__tools">
          <UserBar/>
          <MobileNavigationToggle
              :isActive="isMobileNavigationActive"
              @click="handleMobileNavigationToggle"
          />
        </div>
      </div>
    </div>
  </header>
  <MobileNavigation :isActive="isMobileNavigationActive"/>
</template>

<script>
import MobileNavigation from "@/components/MobileNavigation";
import MobileNavigationToggle from "@/components/MobileNavigationToggle";
import NavigationList from "@/components/NavigationList";
import TheHeaderNavigationList from "@/components/TheHeaderNavigationList";
import UserBar from "@/components/UserBar";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  data() {
    return {
      // if scroll lower than 0, set class
      isPageScrolled: false,
      // helper parameter for check scroll
      didPageScrollMove: false,
      // if header is hidden or not
      isHeaderVisible: false,
      // store last scroll position
      lastPageScrollPosition: 0,
      // store interval
      intervalId: 0,
    }
  },
  components: {
    MobileNavigationToggle,
    NavigationList,
    MobileNavigation,
    UserBar,
    TheHeaderNavigationList
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.checkScroll()
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
    ...mapState("app", {
      isMobileNavigationActive: (state) => state.isMobileNavigationActive,
    })
  },
  methods: {
    ...mapActions("app", ["toggleMobileNavigation"]),
    handleMobileNavigationToggle() {
      if (!this.isMobileNavigationActive) {
        this.isPageScrolled = false;
      }
      this.toggleMobileNavigation();
    },
    handleScroll() {
      // set variable on scroll
      this.didPageScrollMove = true;

      // check if 
      this.isPageScrolled = window.top.scrollY > 5;
    },
    // start interval to check header position
    // hide header on scroll down and show on scroll up
    checkScroll() {
      this.intervalId = setInterval(() => {
        // if page scrolled and mobile navigation is not active
        if (this.didPageScrollMove && !this.isMobileNavigationActive) {

          // get page scroll position
          let scrollPosition = window.top.scrollY;

          // get header height
          let headerHeight = this.$refs.theHeader.clientHeight;

          // magic 
          if (Math.abs(this.lastPageScrollPosition - scrollPosition) <= this._.delta) {
            return 0;
          }

          // count variable 
          this.isHeaderVisible = scrollPosition > this.lastPageScrollPosition && scrollPosition > headerHeight;

          // save last scroll position
          this.lastPageScrollPosition = scrollPosition;

          // reset scroll variable
          this.didPageScrollMove = false;
        }
      }, 60);
    }
  },
  // remove listener for scroll event
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  // clear time interval ID 
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
}
</script>