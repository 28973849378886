import store from "@/store";

/**
 * Check if user has access to current page
 * @param {string} page - current page
 */
function checkAccess(page) {
    const loggedIn = store.getters['user/loggedIn'];
    location.page = page;
    let redirect = '';

    // redirect out of auth pages is user is logged
    if (page === 'login' || page === 'reset' || page === 'registration' || page === 'newPassword') {
        if (loggedIn) {
            redirect = '/courses'
        }
    } else if (page === 'privacyPolicy' || page === 'personalData' || page === '404' || page === '401') {
        // do nothing on index
    } else {
        // redirect out pages is user is not logged
        if (!loggedIn) {
            redirect = '/login'
        }
    }

    if (redirect) {
        window.location.href = redirect
    } else {
        store.dispatch('page/setPage', page)
    }
}

export const authorizationService = {
    checkAccess,
};