<template>
  <div
      class="highlightedSection"
      :class="[color ? 'highlightedSection--' + color : null]"
  >
    <div
        class="container"
        :class="[size ? `container--${size}` : '']"
    >
      <h2 class="highlightedSection__title">{{ title }}</h2>
      <p class="highlightedSection__text" v-if="text">{{ text }}</p>
      <div class="highlightedSection__content" v-if="$slots.default">
        <slot/>
      </div>
    </div>
    <div class="highlightedSection__after" v-if="$slots.after">
      <slot name="after"/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'xxxxs'
    }
  }
}
</script>