<template>
  <Transition name="mobileNavigation">
    <div v-if="isActive" :class="{'mobileNavigation--logged' : loggedIn}" class="mobileNavigation">
      <div class="mobileNavigation__wrapper container">
        <div class="mobileNavigation__inner">
          <NavigationList
              class="mobileNavigation__nav"
              :label="'Main'"
          >
            <TheHeaderNavigationList/>
          </NavigationList>
          <LogoutButton class="mobileNavigation__logout">
            {{ translation('auth_action-logout') }}
            <img
                src="../assets/images/icons/logout.svg"
                :alt="translation('auth_action-logout')"
                class="icon"
                width="24"
                height="28"
                loading="lazy"
            >
          </LogoutButton>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import LogoutButton from "@/components/LogoutButton";
import NavigationList from "@/components/NavigationList";
import TheHeaderNavigationList from "@/components/TheHeaderNavigationList";
import codeBooksMixin from "@/mixins/codeBooksMixin";
import {mapGetters} from "vuex";

export default {
  props:{
    isActive:{
      type: Boolean,
      required: true
    }
  },
  mixins: [codeBooksMixin],
  components: {
    NavigationList,
    LogoutButton,
    TheHeaderNavigationList
  },
  computed:{
    ...mapGetters("user", ["loggedIn"]),
  }
}
</script>