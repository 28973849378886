<template>
  <div class="baseCheckbox">
    <input
        :id="uuid"
        type="checkbox"
        :class="error ? 'is-invalid' : ''"
        :aria-describedby="error ? `${uuid}-error` : null"
        :aria-invalid="error ? true : false"
        :checked="modelValue"
        v-on:change="$emit('update:modelValue', $event.target.checked)"
    />
    <label :for="uuid">
      <slot/>
    </label>
    <FormValidation
        v-if="error"
        :id="`${uuid}-error`"
    >
      {{ error }}
    </FormValidation>
  </div>
</template>

<script>
import FormValidation from '@/components/FormValidation'

export default {
  props: {
    modelValue: {
      type: Boolean
    },
    uuid: {
      type: String,
      required: true
    },
    error: {
      type: String,
      default: ''
    }
  },
  components: {
    FormValidation
  }
}
</script>
