export default {
    namespaced: true,
    state: {
        currentAssessment: null,
        answersJson: {}
    },

    mutations: {
        SET_ASSESSMENT(state, data) {
            state.currentAssessment = data
        },
        SET_ANSWERS_JSON(state, data) {
            state.answersJson[data.key] = data.value;
        },
    },

    actions: {
        setAssessment({commit, state}, data) {
            commit('SET_ASSESSMENT', data);
        },

        setAnswersJson({commit, state}, data) {
            commit('SET_ANSWERS_JSON', data);
        },
    }
}