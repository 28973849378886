// 400
export class BadRequestError extends Error {
    constructor(message) {
        super(message);
        this.name = "BadRequestError";
    }
}

// 401
export class UnauthorizedError extends Error {
    constructor(message, data) {
        super(message);
        this.name = "UnauthorizedError";
        this.data = data;
    }
}

// 403
export class ForbiddenError extends Error {
    constructor(message, data) {
        super(message);
        this.name = "PermissionDeniedError";
        this.data = data;
    }
}

// 404
export class NotFoundError extends Error {
    constructor(message) {
        super(message);
        this.name = "NotFoundError";
    }
}

// 408
export class RequestTimeout extends Error {
    constructor(message) {
        super(message);
        this.name = "RequestTimeout";
    }
}

// 422
export class ValidationError extends Error {
    constructor(message, data) {
        super(message);
        this.name = "ValidationError";
        this.data = data;
    }
}

// 500
export class InternalServerError extends Error {
    constructor(message) {
        super(message);
        this.name = "InternalServerError";
    }
}

// Other
export class AppError extends Error {
    constructor(message, data) {
        super(message);
        this.name = "AppError";
        this.data = data;
    }
}